import React, { useEffect, useState } from 'react';
import { getDashboard } from '../../services/dashboard';

function ConsumptionAnalysis(): JSX.Element {
  const [dashboardURL, setDashboardURL] = useState('');

  useEffect(() => {
    const getDashboardURL = async () => {
      const selectedCustomerName = localStorage.getItem('selected_customer_name');
      const response = await getDashboard(['analise_consumo_menu'], selectedCustomerName);

      setDashboardURL(response.data[0].analise_consumo_menu);
    };
    getDashboardURL();
  }, []);

  return (
    <>
      <div className="main-content">
        <iframe src={dashboardURL} />
      </div>
    </>
  );
}

export default ConsumptionAnalysis;
