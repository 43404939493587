import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { Container, Content, ContainerClosed } from './styles';
import {
  FaClock,
  FaBars,
  FaRegCalendarCheck,
  FaCalculator,
  FaRegFileAlt,
  FaSignOutAlt,
  FaCog,
  FaUserTag,
  FaCalendar,
} from 'react-icons/fa';
import { BsFillMegaphoneFill } from 'react-icons/bs';
import SidebarItem from '../SidebarItem';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { logout } from '../../services/auth';
import { getAccessToken, userLogout } from '../../services/localStorage';
import { useHistory } from 'react-router-dom';
import fiscalYearCalendar from '../../assets/icons/fiscalYear-icon';

interface MenuItem {
  title: string;
  icon: any;
  url: string;
  disabled?: boolean;
}

const Sidebar = forwardRef((props, ref) => {
  const [sideBarState, setSideBarState] = useState<boolean>(false);
  const location = useLocation();
  const [shouldRenderSidebar, setShouldRenderSidebar] = useState<boolean>(true);
  const lookerAccessToken = localStorage.getItem('looker_access_token');
  const customerName = localStorage.getItem('selected_customer_name');

  const menuItems: Array<MenuItem> = [
    {
      title: 'Clientes',
      icon: FaUserTag,
      url: '/clientes',
    },
    {
      title: 'Consumo mensal',
      icon: FaRegCalendarCheck,
      url: '/consumo_mensal',
      disabled: !lookerAccessToken,
    },
    {
      title: 'Orçamento',
      icon: FaCalculator,
      url: '/orcamento',
      disabled: !lookerAccessToken,
    },
    {
      title: 'Detalhes',
      icon: FaRegFileAlt,
      url: '/detalhes',
      disabled: !lookerAccessToken,
    },
    {
      title: 'Análise de Consumo por dia e por Hora',
      icon: FaClock,
      url: '/analise_consumo',
      disabled: !lookerAccessToken,
    },
    {
      title: 'Recomendações por dia e por Hora',
      icon: FaCog,
      url: '/recomendacoes',
      disabled: !lookerAccessToken || customerName.includes('Multi'),
    },
    {
      title: 'Ano Fiscal',
      icon: fiscalYearCalendar,
      url: '/anofiscal',
      disabled: !lookerAccessToken,
    },
    {
      title: 'Grupos',
      icon: FaCog,
      url: '/grupos',
      disabled: !lookerAccessToken,
    },
    // {
    //   title: 'News Feeds',
    //   icon: BsFillMegaphoneFill,
    //   url: '#',
    //   disabled: !lookerAccessToken,
    // },
    {
      title: 'Sair',
      icon: FaSignOutAlt,
      url: '/logout',
    },
  ];

  const history = useHistory();

  const handleLogout = async () => {
    try {
      const accessToken = getAccessToken();
      await logout(accessToken);
      userLogout();
      history.push('/');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      setShouldRenderSidebar(false);
    } else {
      setShouldRenderSidebar(true);
    }
  }, [location.pathname]);

  useImperativeHandle(ref, () => ({
    changeSideBarStatus(status: boolean) {
      setSideBarState(status);
    },
  }));

  if (!shouldRenderSidebar) {
    return null; // Não renderiza o sidebar se shouldRenderSidebar for falso
  }

  return sideBarState ? (
    <Container
      onMouseEnter={() => setSideBarState(true)}
      onMouseLeave={() => setSideBarState(false)}
      sidebar={sideBarState}>
      <div
        style={{
          height: 60,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          borderBottom: '4px solid #237458',
          marginLeft: 15,
          paddingLeft: 15,
          marginRight: 15,
          borderRadius: 2,
        }}>
        <FaBars style={{ width: 20, height: 20 }} onClick={() => setSideBarState(true)} />
      </div>

      <Content>
        {menuItems.map((item: MenuItem, index: number) => {
          if (item.title === 'Sair') {
            return (
              <div key={`${index}${item.url} `} onClick={handleLogout}>
                <SidebarItem Icon={item.icon} Text={item.title} />
              </div>
            );
          }
          return (
            <Link key={`${index}${item.url} `} to={item.disabled ? '#' : item.url}>
              <SidebarItem Icon={item.icon} Text={item.title} disabled={item.disabled} />
            </Link>
          );
        })}
      </Content>
    </Container>
  ) : (
    <ContainerClosed
      onMouseEnter={() => setSideBarState(true)}
      onMouseLeave={() => setSideBarState(false)}
      sidebar={sideBarState}>
      <div
        style={{
          height: 60,
          width: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '4px solid #237458',
          marginLeft: 15,
          marginRight: 15,
          borderRadius: 2,
        }}>
        <FaBars style={{ width: 20, height: 20 }} onClick={() => setSideBarState(true)} />
      </div>

      <Content>
        {menuItems.map((item: MenuItem, index: number) => (
          <Link key={`${index}${item.url} `} to={item.disabled ? '#' : item.url}>
            <SidebarItem Icon={item.icon} Text={''} disabled={item.disabled} />
          </Link>
        ))}
      </Content>
    </ContainerClosed>
  );
});

Sidebar.displayName = 'Sidebar';

export default Sidebar;
